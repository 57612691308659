/* default style */
.button {
  transition: color 300ms ease-in, background-color 300ms ease-in;
}

.rounded {
  border-radius: 9999px;
}

/* button size */
.small {
  min-height: 40px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.standard {
  min-height: 48px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.large {
  min-height: 56px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 8px;
  padding-bottom: 8px;
}

/* button type */
.primary {
  background: var(--blue-50);
  color: var(--white);
}

.primary:hover {
  background: var(--blue-70);
}

.secondary {
  background: var(--blue-10);
  color: var(--blue-50);
}

.secondary:hover {
  background: var(--blue-50);
  color: var(--white);
}

.tertiary {
  background: var(--yellow-50);
  color: var(--gray-90);
}

.tertiary:hover {
  background: var(--yellow-60);
}

.pill {
  background: transparent;
  color: var(--gray-90);
  font-size: 13px;
  height: 26px;
  padding-left: 16px;
  padding-right: 16px;
  min-height: auto;
  padding-top: 2px;
  padding-bottom: 2px;
}

.pill:hover {
  background: var(--gray-10);
}

.pill:global(.active) {
  background: var(--gray-90);
  color: var(--white);
}

.orangePill {
  color: var(--orange-50);
  padding-left: 16px;
  padding-right: 16px;
  min-height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}

.orangePill:hover {
  background: var(--orange-10);
}

.orangePill:global(.active) {
  background: var(--orange-50);
  color: var(--white);
}

.yellowPill {
  color: var(--yellow-30);
  border: 1px solid var(--yellow-30);
  padding-left: 44px;
  padding-right: 44px;
  min-height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}

.yellowPill:hover {
  background: var(--yellow-30);
  color: var(--blue-web-50);
}

.yellowPill:global(.active) {
  background: var(--yellow-30);
  color: var(--blue-web-50);
}

/* button disabled */
.button:disabled {
  cursor: not-allowed;
  background: var(--gray-40);
  color: var(--gray-10);
}

.button:disabled:hover {
  background: var(--gray-40);
  color: var(--gray-10);
}

/* loading prop */
.loader {
  animation: loading 1.4s infinite linear;
  transform: translateZ(0);
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
